import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import map from 'lodash/map';

import { aboutWhoTypes } from '../types/propTypes';
import { Container, Inner } from './Elements';
import SectionHeading from './SectionHeading';
import { colors, jcc, font } from '../consts/style';
import ModalContext from '../store/modalContext';
import * as modalTypes from '../types/modalTypes';
import mq from '../style/mq';

const Wrapper = styled.div`
  margin-top: 8rem;
  margin-bottom: 12rem;
`;
const TeamBlock = styled.div`
  width: 32%;
  margin-right: 1.5%;
  margin-bottom: 3%;
  &:nth-child(3n) {
    margin-right: 0;
  }
  ${mq.tabletSmall`
    width: 49%;
    margin-right: 2%;
    margin-bottom: 2%;
    &:nth-child(even) {
      margin-right: 0;
    }
    &:nth-child(3) {
      margin-right: 2%;
    }
  `}
  ${mq.mobile`
    width: 100%;
    margin-bottom: 2rem;
    &:nth-child(even) {
      margin-right: 0;
    }
  `}
  .name {
    ${font.h4};
    margin: 1.2rem 0 0.8rem;
  }
  .image-wrap {
    position: relative;
    width: 100%;
    padding-bottom: 140%;

    .click-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.4s ease;
      border: none;
      ${jcc}
      p {
        ${font.h4};
        text-transform: uppercase;
        color: white;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
`;
const TeamContainer = styled.div`
  width: 90rem;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 6rem;
`;
export const AbsoluteImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`;
export default function AboutWho({ team, title }) {
  const { openModal } = useContext(ModalContext);
  function renderTeamMembers() {
    return map(team, (t) => (
      <TeamBlock key={t.id}>
        <div className="image-wrap">
          <AbsoluteImage fluid={t.image.fluid} backgroundColor={colors.blue} />
          <button
            className="click-mask"
            onClick={() => openModal(modalTypes.TEAM, t)}
          >
            <p>Read more</p>
          </button>
        </div>
        <p className="name">{t.name}</p>
        <p className="title">{t.title}</p>
      </TeamBlock>
    ));
  }

  return (
    <Fragment>
      <Wrapper>
        <Container>
          <Inner>
            <SectionHeading heading={title} noUnderline />
            <TeamContainer>{renderTeamMembers()}</TeamContainer>
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}

AboutWho.propTypes = aboutWhoTypes;
