import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { BsInstagram, BsLinkedin } from 'react-icons/bs';
import { FaLink } from 'react-icons/fa';

import SEO from '../components/SEO';
import { Container, Inner } from '../components/Elements';
import { font, colors } from '../consts/style';

import Cat from '../images/cat-black.png';
import { AbsoluteImage } from '../components/AboutWho';
const CatIcon = styled.img.attrs({ src: Cat, alt: 'Cat icon' })``;

const Wrapper = styled.div`
  background: white;
  padding-top: 2.4rem;
  padding-bottom: 4rem;
  ${Inner} {
    width: 100%;
    max-width: 80rem;
  }
`;

const HeaderImage = styled.div`
  width: 100%;
  margin-bottom: 2.4rem;
`;
const Date = styled.p`
  color: ${colors.blue};
  ${font.blogBody};
  text-transform: uppercase;
  span {
    background: ${colors.orange};
    padding: 0.2rem 0.4rem;
    font-weight: bold;
  }
`;
const Bio = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  color: ${colors.blue};
  .image {
    width: 8rem;
    margin-right: 2rem;
    .author {
      position: relative;
      width: 8rem;
      height: 12rem;
      border-radius: 4px;
      overflow: hidden;
    }
    img {
      width: 100%;
    }
  }

  .bio {
    p {
      font-size: 1.2rem;
      margin: 0;
      letter-spacing: unset !important;
    }
  }

  .links {
    a {
      margin-right: 1.2rem;
    }
  }
  p {
    margin-top: 1.2rem;
    margin-bottom: 0;
    font-family: 'Karla';
    letter-spacing: 0.2rem;

    a {
      color: ${colors.blue};
      font-weight: bold !important;
      text-decoration: underline;
      &:hover {
        font-weight: bold !important;
        color: ${colors.blue};
      }
    }
  }
`;
const Title = styled.h1`
  ${font.h1blog};
  color: ${colors.blue};
  margin-bottom: 1.2rem;
  text-transform: uppercase;
`;
export const Content = styled.div`
  color: ${colors.darkText};
  p,
  span,
  ul,
  ol {
    ${font.blogBody};
  }
  p,
  h1,
  h2,
  h3,
  h4,
  ul,
  ol,
  blockquote,
  img {
    margin-bottom: 2.8rem;
  }
  img {
    max-width: 100%;
  }
  h1 {
    ${font.h1blog};
    color: ${colors.blue};
    padding-top: 1.2rem;
  }
  h2 {
    ${font.h2blog};
    color: ${colors.blue};
    padding-top: 1.2rem;
  }
  h3 {
    ${font.h3blog};
    color: ${colors.blue};
    padding-top: 1.2rem;
  }
  h4 {
    ${font.h4blog};
    color: ${colors.blue};
    padding-top: 1.2rem;
  }
  a {
    color: ${colors.blue};
    font-weight: bold !important;
    text-decoration: underline;
    &:hover {
      font-weight: bold !important;
      color: ${colors.blue};
    }
  }

  table {
    border: 1px solid black;
    border-collapse: collapse;
    tr {
      border-collapse: collapse;
      border-bottom: 1px solid black;
    }
    td {
      border-collapse: collapse;
      border-right: 1px solid black;
      padding: 4px;
    }
  }

  blockquote {
    background: rgba(255, 255, 255, 0.2);
    border-left: 1rem solid ${colors.blue};
    padding: 1.2rem 2rem;
    p {
      ${font.p};
      font-size: 1.6rem;
      margin: 0;
    }
  }
`;

const BlogPost = ({ data }) => {
  const headerImageData = data.blog.featuredImage?.fluid;

  const author =
    data.blog.authorProfile?.name || data.blog.author || 'Copycat Staff';
  const profile = data.blog.authorProfile?.linkedinProfile;
  const website = data.blog.authorProfile?.website;
  const insta = data.blog.authorProfile?.instagram;
  const bio = data.blog.authorProfile?.bio;
  const authorImage = data.blog.authorProfile?.photo?.fluid;

  const hasLinks = Boolean(profile || website || insta);

  return (
    <Fragment>
      <SEO meta={data.blog.seoMetaTags} />
      <Wrapper>
        <Container>
          <Inner>
            {headerImageData && (
              <HeaderImage>
                <Img fluid={headerImageData} />
              </HeaderImage>
            )}
            <Date>
              <span>{data.blog.meta.firstPublishedAt}</span>
            </Date>
            <Title>{data.blog.title}</Title>
            <Bio>
              <div className="image">
                {authorImage ? (
                  <div className="author">
                    <AbsoluteImage fluid={authorImage} />
                  </div>
                ) : (
                  <CatIcon />
                )}
              </div>
              <div className="text">
                <p>
                  Written by <strong>{author}</strong>
                </p>
                {bio && (
                  <div
                    className="bio"
                    dangerouslySetInnerHTML={{ __html: bio }}
                  />
                )}
                {hasLinks && (
                  <div className="links">
                    {insta && (
                      <a href={insta} rel="noreferrer" target="_blank">
                        <BsInstagram color={colors.orange} />
                      </a>
                    )}
                    {profile && (
                      <a href={profile} rel="noreferrer" target="_blank">
                        <BsLinkedin color={colors.orange} />
                      </a>
                    )}
                    {website && (
                      <a href={website} rel="noreferrer" target="_blank">
                        <FaLink color={colors.orange} />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </Bio>
            <Content
              dangerouslySetInnerHTML={{
                __html: data.blog.content,
              }}
            />
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
};

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export const blogQuery = graphql`
  query($slug: String!) {
    fallback: file(relativePath: { eq: "fallback.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    blog: datoCmsBlogPost(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      content
      featuredImage {
        fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      meta {
        firstPublishedAt(formatString: "MMMM Do, YYYY")
      }
      author
      authorProfile {
        name
        linkedinProfile
        website
        instagram
        bio
        photo {
          fluid(maxWidth: 200, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`;

export default BlogPost;
